<template>
  <div class="order">
    <!-- 搜索区域 -->
    <div class="header">
      <el-input class="input" v-model="input" placeholder="输入订单号"></el-input>
      <div class="button-two">
        <el-button type="primary" @click="searchInp">查询</el-button>
        <el-button type="primary">
          <router-link to="/order/insert" class="goto">新增订单</router-link>
        </el-button>
      </div>
    </div>
    <!-- 表格区域 -->
    <div class="wrapper">
      <el-table :data="tableData" v-loading="loading" style="width: 100%">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="order_member" label="订单号" width="300"></el-table-column>
        <el-table-column prop="name" label="课程名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="goods_price" label="订单金额" width="100"></el-table-column>
        <el-table-column prop="goods_count" label="下单数量" width="100"></el-table-column>
        <el-table-column prop="commission" label="赚佣金" width="100"></el-table-column>
        <el-table-column prop="created" label="下单日期"></el-table-column>
        <el-table-column prop="status" label="是否支付" show-overflow-tooltip></el-table-column>
        <el-table-column prop="chain_name" label="所属机构" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.row)">查看</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="订单信息" :visible.sync="dialogCode" :before-close="close">
      <el-table :data="gridData">
        <el-table-column property="shop_user_name" label="所属员工"></el-table-column>
        <el-table-column property="shop_chain_name" label="所属机构"></el-table-column>
        <el-table-column property="userName" label="客户姓名"></el-table-column>
        <el-table-column property="telNumber" label="客户手机号"></el-table-column>
        <el-table-column property="address" label="客户地址"></el-table-column>
      </el-table>
    </el-dialog>
    <!-- 分页 -->
    <MyPagination :total="total" :pageSize="query_info.limit" @changePage="changePage" :currentPage="query_info.page" />
  </div>
</template>

<script>
import { shopOrderlist, getMember, delOrder } from '../../../request/api';
import MyPagination from '../../../components/MyPagination.vue'
import { mapState } from 'vuex'

export default {
  inject: ['reload'],
  components: {
    MyPagination
  },
  data () {
    return {
      input: '',
      tableData: [],
      query_info: {
        chain_id: '',
				limit: 10,
				page: 1
      },
      total: 0,
      dialogCode: false,
      gridData: [],
      loading: false
    }
  },
  computed: {
    ...mapState('m_login', ['loginData'])
  },
  methods: {
    // 获取订单列表
    async getOrderList () {
      try {
        this.loading = true
        const res = await shopOrderlist(this.query_info.chain_id, this.query_info.limit, this.query_info.page, this.input)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        this.tableData = res.data.data.orderList.filter(element => {
          element.commission = element.commission + '元'
          return element.status === 0 ? element.status = '未支付' : element.status = '已支付'
        })
        this.total = res.data.data.totalNumber
      } finally {
        this.loading = false
      }
    },
    // 自定义事件 取到分页的页码
    changePage (page) {
      this.query_info.page = page
      this.tableData = []
      this.getOrderList()
    },
    // 搜索按钮
    searchInp () {
      this.query_info.page = 1
      this.tableData = []
      this.getOrderList()
    },
    // 删除按钮
    handleDelete (row) {
      this.$confirm('是否删除该商品？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        let postData = {
          shop_order_id: row.shop_order_id
        }
        const res = await delOrder(postData)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        this.$message({
          type: 'success',
          message: '删除成功！'
        })
        this.reload()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async handleEdit (row) {
      const res = await getMember(row.shop_member_add_id)
      if (res.status !== 200 || res.data.code !== '1000') this.$message.error(res.data.msg)
      this.gridData.push(res.data.data)
      this.dialogCode = true
    },
    close () {
      this.gridData = []
      this.dialogCode = false
    }
  },
  created () {
    this.query_info.chain_id = this.loginData.chain_id
  },
  mounted () {
    this.getOrderList()
  }
}
</script>

<style lang="less" scoped>
.order {
  margin: 20px;
  .header {
    display: flex;
    .input {
      width: 240px;
    }
    .button-two {
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin-left: 10px;
      margin-right: 20px;
      .goto {
        color: #fff;
      }
    }
  }
  .wrapper {
    margin: 20px 0;
  }
}
</style>